<template>
  <section id="dashboard-analytics">
    <iframe style="width: 80vw;height: 100vh;" src="https://app.powerbi.com/view?r=eyJrIjoiYTA2ZmM2MWMtZGU1Ny00YjBlLWFhZDUtMmJlYjdjMWYxODlkIiwidCI6IjBjZTA4ZGQ0LTI3MjgtNDlmYS04ZTcxLWFjYjRhM2MzZDE0NSIsImMiOjh9"></iframe>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
